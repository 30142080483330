
// style
import Loader from "../../../../components/Loader";
import "./GenerateFeedbackLoading.scss";

const GenerateFeedbackLoading = (props) => {

    return (
        <div className="GenerateFeedbackLoadingView" >
            <div className="title" >
                Antwoorden opgeslagen, feedback wordt gegenereerd <Loader />
            </div>
            <div className="body">
                Dit kan even duren. Herlaad de pagina alsjeblieft niet.
            </div>
        </div>
    )
}

export default GenerateFeedbackLoading;
