
// react
import { useState } from "react";

// styles
import "./SetUserSettings.scss";

// classes
import ClientData from "../../../../services/classes/clientData/ClientData";

// services
import handleEnvironment from "../../../../services/handleEnvironment/handleEnvironment";

// components
import SettingsInput from "../../../MyProfile/components/Settings/components/SettingsInput/SettingsInput";

// constants
import { UVA_ENV } from "../../../../services/handleEnvironment/constants";
import { ALLOW_GENERATED_FEEDBACK_KEY, ALLOW_TRAINING_KEY } from "../../../MyProfile/components/Settings/utils/constants";

const SetUserSettings = (props) => {

    // data
    const [userSettings, updateUserSettings] = useState(props.userSettings === null ? 
        new ClientData({
            post: {
                [ALLOW_GENERATED_FEEDBACK_KEY]: null,
                [ALLOW_TRAINING_KEY]: null,
            }
        }) : 
        new ClientData({
            sent: props.userSettings
        })
    );

    const closeAndSubmit = async() => {
        const userSettingResponse = await props.fetchUserSettings(); // for if something goes wrong during saving exam, the user has their latest settings
        if (props.submitExam) {
            props.submitExam(userSettingResponse);
        }
        props.toggleShow(false);
    }

    const onSaveSettingsFailure = () => {
        // to ensure that students can still submit if posting settings does not work.
        props.updateUserSettings({
            [ALLOW_GENERATED_FEEDBACK_KEY]: false,
            [ALLOW_TRAINING_KEY]: false,
        });
        props.toggleShow(false);
    }

    return (
        <div className="SetUserSettingsView" >
            <div className="title" >
                Accountinstellingen Instellen
            </div>
            <div className="subTitle">
                Limitaties
            </div>
            <div className="body">
                Wij gebruiken een Large Language Model (LLM) om feedback en punten te genereren voor elke vraag op basis van jouw antwoorden op oefenvragen. 
                Dit systeem, gebaseerd op kunstmatige intelligentie, produceert automatisch feedback zonder menselijke tussenkomst. Het model kan onjuiste feedback of punten opgeven. 
                Zorg er dus voor dat je de gegenereerde feedback en punten niet blindelings vertrouwt.
            </div>

            {
                handleEnvironment() === UVA_ENV ?
                <>
                    <div className="subTitle">
                        Data
                    </div>
                    <div className="body">
                        Jouw antwoorden worden verwerkt op servers van Microsoft, in overeenstemming met de contractuele afspraken van de UvA. Jouw gegevens worden niet opgeslagen door externe partijen en niet zonder toestemming gebruikt voor verdere training van het model.
                    </div>
                </> : null
            }

            <div className="subTitle">
                Wachttijd
            </div>
            <div className="body">
                Het genereren van punten en feedback kan tot ongeveer 50 seconden duren.
            </div>

            <div className="subTitle">
                Contact
            </div>
            <div className="body">
                Heb je vragen? Stuur dan een e-mail naar olaf@etalia.nl of een WhatsApp-bericht naar 0623991946.
            </div>

            <div className="subTitle">
                Settings Aanpassen
            </div>
            <div className="body">
                De instellingen die je hier invoert, gelden voor alle toekomstige inleveringen. Je kunt je instellingen op elk moment aanpassen door naar je account te gaan en vervolgens naar instellingen te navigeren.
            </div>

            <div className="marginTop">
                <SettingsInput
                    submitButtonText={props.submitExam ? "Opslaan en Inleveren" : null}
                    afterSaveSuccess={closeAndSubmit}
                    afterSaveSettingsFailure={onSaveSettingsFailure}
                    cancle={() => props.toggleShow(false)}
                    clientData={userSettings}
                />
            </div>
        </div>
    );
}

export default SetUserSettings;
