
import { BAD_REQUEST, SUCCESS } from "../../../../../../services/errors/constants";

const validateGenerateFeedbackAllowed = (value) => {

    switch (value) {
        case "true":
            return SUCCESS;
        case "false":
            return SUCCESS;
        case true:
            return SUCCESS;
        case false:
            return SUCCESS;
        default:
            return BAD_REQUEST;
    }
}

export default validateGenerateFeedbackAllowed;
