
import { useState } from "react";

// styles
import "./review.scss";

// classes
import errorClass from "../../../../../../../../../services/errors/classes/errorClass";
import CustomErrorsManager from "../../../../../../../../../services/errors/classes/CustomErrorsManager";

// services
import networking from "../../../../../../../../../services/handleNetworking/networking/networking";
import reviewFeedbackAPI from "../../../../../../../../../services/Networking/reviewFeedback/reviewFeedback";

// components
import Redux from "../../../../../../../../../components/Redux/Redux";

// elements
import ThumbsUp from "../../../../../../../../../elements/Thumbs/ThumbsUp";
import ThumbsDown from "../../../../../../../../../elements/Thumbs/ThumbsDown";
import Loader from "../../../../../../../../../components/Loader/Loader";

// constants
import { NO_REVIEW, THUMBS_DOWN, THUMBS_UP } from "./utils/constants";
import { INTERNAL_ERROR, NOT_FOUND, SUCCESS, UNAUTHORIZED } from "../../../../../../../../../services/errors/constants";
import { REDUX_ERRORS } from "../../../../../../../../../components/Redux/services/constants";

const Review = (props) => {

    // states
    const [loading, toggleLoading] = useState(false);
    const [review, updateReview] = useState(props.reviewFeedback);

    // errors
    const [postErrors, updatePostErrors] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const reviewFeedback = async(thumbsUp) => {
        const returned = await networking({
            toggleLoading: toggleLoading,
            errorOnSuccess: false,

            api: thumbsUp ? reviewFeedbackAPI.postThumbsUp : reviewFeedbackAPI.postThumbsDown,
            apiParams: props.aiEvaluationId,

            updateError: updatePostErrors,
            customErrors: (errorCode) => new CustomErrorsManager(
                {
                    [INTERNAL_ERROR]: {
                        title: "Er is een fout opgetreden bij het opslaan van je feedback op de gegenereerde feedback."
                    },
                    [NOT_FOUND]: {
                        title: "Er is een fout opgetreden bij het opslaan van je feedback op de gegenereerde feedback."
                    }
                }
            ).getError(errorCode)
        });
        if (returned && returned.status === SUCCESS) {
            updateReview(thumbsUp ? THUMBS_UP : THUMBS_DOWN);
        }
    }

    return (
        <>
            <div className="questionFeedbackReview" >{
                loading ? <Loader /> :
                review === NO_REVIEW ?
                <>
                    <div className="thumbsUpHolder" onClick={() => reviewFeedback(true)} >
                        <ThumbsUp />
                    </div>
                    <div className="thumbsDownHolder" onClick={() => reviewFeedback(false)}  >
                    <ThumbsDown />
                    </div>
                </>
                : "Feedback opgeslagen, bedankt!"
            }</div>

            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}

                reduxVar={postErrors}
            />
        </>
    )
}

export default Review;
