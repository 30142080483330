
// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';

// style
import "./thumbs.scss";

const ThumbsDown = (props) => {
    return (
        <FontAwesomeIcon 
            onClick={props.onClick}
            className="thumbs down"
            icon={faThumbsDown}
        />
    )
}

export default ThumbsDown;
