
// standard objects
import errorClass from '../../../../services/errors/classes/errorClass';
// constants
import { SUCCESS, UNAUTHORIZED, CONFLICT } from "../../../../services/errors/constants";

const profileErrors = (errorCode) => {

    if (!errorCode) {
        return null;
    }
    
    switch (errorCode) {

        case SUCCESS:

            return new errorClass({
                errorCode: errorCode,
                priority: 1,
                title: "Profile Updated!",
                description: "Profile updated successfully",
                advice: null,
                moreInfo: null,
            });

        // add incorrect password
        case UNAUTHORIZED:

            return new errorClass({
                errorCode: UNAUTHORIZED,
                priority: 1,
                title: "Unauthorized",
                description: "You are not authroized.",
                advice: null,
                moreInfo: null
            });

        case CONFLICT:

            return new errorClass({
                errorCode: CONFLICT,
                priority: 1,
                title: "Invalid value",
                description: "Make sure to fill all field correctly.",
                advice: null,
                moreInfo: null
            });

        default:
            return null;
    }

}

export default profileErrors;
