
// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

// style
import "./thumbs.scss";

const ThumbsUp = (props) => {
    return (
        <FontAwesomeIcon 
            onClick={props.onClick}
            className="thumbs up"
            icon={faThumbsUp}
        />
    )
}

export default ThumbsUp;
