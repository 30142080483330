
import { useState, useEffect } from "react";

// routing
import { useParams, useLocation } from 'react-router-dom';

// styles
import "./GradeExam.scss";

// classes
import ClientData from "../../../../../../../../services/classes/clientData/ClientData";
import StickyButtonClass from "../../../../../../components/StickyButtons/StickyButtonClass";
import CustomErrorsManager from "../../../../../../../../services/errors/classes/CustomErrorsManager";
import errorClass from "../../../../../../../../services/errors/classes/errorClass";

// services
import networking from "../../../../../../../../services/handleNetworking/networking";
import assignmentApi from "../../../../../../../../services/Networking/assignment/assignment";
import takenExamsApi from "../../../../../../../../services/Networking/takenExams/takenExams";
import formatCheckedAnswers from "../../../../../CheckExam/services/formatCheckedAnswers";

// components
import ExamInfoAssignedExam from "../../../../../../components/ExamInfo/ExamInfoAssignedExam";
import ExamFeedback from "../../../../../../components/ExamFeedback/ExamFeedback";
import Title from "../../../../../../components/Title";
import Loader from "../../../../../../../../components/Loader";
import Redux from "../../../../../../../../components/Redux/Redux";
import List from "../../../../../../../../components/List";
import Question from "../../../../../../components/Question";
import initQuestionHubs from "../../../../../../services/initQuestionHubs";
import StickyButtons from "../../../../../../components/StickyButtons";
import ExamPointsGraph from "../../../../../../components/Statistics/ExamPointsGraph";

// constants
import { REDUX_ERRORS } from "../../../../../../../../components/Redux/services/constants";
import { CHECK } from "../../../../../../services/tabConstants";
import { INTERNAL_ERROR, MAIN_OPERATION_SUCCESS, SUCCESS, UNAUTHORIZED } from "../../../../../../../../services/errors/constants";

const GradeExam = (props) => {

    // routing
    let location = useLocation();
    let { assignmentID, examID } = useParams();

    /*
        props:
            - SelectedViewType - might be in filters, exam or question
            - Title
            - Questions
    */
    // data
    const [exam, updateExam] = useState(null);
    const [assignment, updateAssignment] = useState(null);

    // states
    const [loadingGet, toggleLoadingGet] = useState(true);
    const [loadingSavingValidated, toggleLoadingSavingValidated] = useState(false);
    const [getAssignmentLoading, toggleGetAssignmentLoading] = useState(false);
    const [loadingSaving, toggleLoadingSaving] = useState(false);
    const [showExamInfo, toggleShowExamInfo] = useState(true);
    const [showQuestionInfo, toggleShowQuestionInfo] = useState(false);

    // errors
    const [getExamError, updateGetExamError] = useState(null);
    const [getAssignmentError, updateGetAssignmentError] = useState(null);
    const [validateAssignmentError, updateValidateAssignmentError] = useState(null);

    const formatExam = (fetchedExam) => {
        const formattedQuestions = initQuestionHubs({ sentQuestions: fetchedExam?.questions });
        updateExam(
            new ClientData({
                id: fetchedExam.examUUID,
                childLists: {
                    feedback: [],
                    questions: formattedQuestions,
                },
                sent: fetchedExam,
            })
        )
    }
    const getExam = async() => {
        const returned = await networking({
            updateContent: formatExam,
            toggleLoading: toggleLoadingGet,
            errorOnSuccess: false,

            api: takenExamsApi.getTakenExamByExamIdAndUserIdAndOrder,
            apiParams: props.selectedExamParams,

            updateError: updateGetExamError,
        });
    }

    const getAssignment = async() => {
        await networking({
            updateContent: updateAssignment,
            toggleLoading: toggleGetAssignmentLoading,
            errorOnSuccess: false,

            api: assignmentApi.getAssignment,
            apiParams: assignmentID,

            updateError: updateGetAssignmentError,
        });
    }

    useEffect(() => {
        if (props.selectedExamParams) {
            getExam();
            getAssignment();
        }
    }, [props.selectedExamParams]);

    const validateExam = async() => {
        if (exam?.sent?.uuid) {
            const returned = await networking({
                toggleLoading: toggleLoadingSavingValidated,
                errorOnSuccess: false,
    
                api: takenExamsApi.takenExamValidated,
                apiParams: exam?.sent?.uuid,
                
                customErrors: (errorCode) => new CustomErrorsManager(
                    {
                        [INTERNAL_ERROR]: {
                            title: "Valideren niet gelukt. Wij zullen het probleem zo snel mogelijk oplossen."
                        },
                        [UNAUTHORIZED]: {
                            title: "Je hebt geen toegang om validateren."
                        }
                    }
                ).getError(errorCode),
                updateError: updateValidateAssignmentError,
            });
            if (returned && returned.status === SUCCESS) {
                getExam();
                props.fetchExams();
            }
        } else {
            updateValidateAssignmentError(new errorClass({
                errorCode: INTERNAL_ERROR,
                message: "Er is iets fout gegaan. Valideren niet gelukt."
            }));
        }
    }

    const save = async() => {
        const formattedFeedbackAndPoints = formatCheckedAnswers(
            examID,
            exam?.childLists?.questions
        );

        const returned = await networking({
            toggleLoading: toggleLoadingSaving,
            errorOnSuccess: false,

            api: takenExamsApi.postCheckedQuestionByExamIdAndUserIdAndOrder,
            apiParams: {
                examUuid: examID, 
                userId: props.selectedExamParams?.userId,
                order: props.selectedExamParams?.order,
                formattedFeedbackAndPoints
            },

            updateError: updateGetAssignmentError,
        });
        
        if (returned && (returned.status === SUCCESS || returned.status === MAIN_OPERATION_SUCCESS)) {
            getExam();
            props.fetchExams();
        }
    }

    const renderQuestion = (i, questionHub) => {
        return (
            <Question
                key={questionHub.getCurrentValue("uuid")}

                examView={CHECK}

                questionHub={questionHub}

                showQuestionInfo={showQuestionInfo}
                toggleShowQuestionInfo={toggleShowQuestionInfo}
            />
        )
    }

    return (
        <div
            className="gradingAssignedExamView"
        >{
            loadingGet && !exam ? <Loader /> :
            !exam ? "Geen tentamen geselecteerd" :
            <>
                
                <StickyButtons
                    buttons={[
                        exam?.getCurrentValue("validated") === true ? null : loadingSavingValidated ? new StickyButtonClass({

                            title: <Loader />,
                            onClickFunc: null,
                            link: null,
                    
                        }) : new StickyButtonClass({
                            title: "Tentamen bekeken",
                            onClickFunc: validateExam,
                            link: null,
                        }),
                        loadingSaving ? new StickyButtonClass({

                            title: <Loader />,
                            onClickFunc: null,
                            link: null,
                    
                        }) : new StickyButtonClass({

                            title: "Opslaan",
                            onClickFunc: save,
                            link: null,
                    
                        })
                    ]}
                />
                
                <Title
                    title={exam?.getCurrentValue("title")}

                    showExamInfo={showExamInfo}
                    toggleShowExamInfo={toggleShowExamInfo}

                    loading={loadingGet || getAssignmentLoading}
                />

                {
                    showExamInfo ?
                    <>
                        <ExamInfoAssignedExam
                            exam={exam}
                            assignment={assignment}
                            assignmentUuid={assignmentID}

                            view={CHECK}
                        />

                        <ExamFeedback
                            exam={exam}
                            updateExam={updateExam}
                        />
                    </> : null
                }

                <ExamPointsGraph
                    takenExamUuId={exam?.sent?.uuid}
                />

                {
                    !exam || !exam?.childLists?.questions ? null :
                    <List
                        items={exam?.childLists?.questions}
                        renderItem={renderQuestion}
                    />
                }

                {/* 
                    Some settings:
                        Get latest exam handed in for 
                */}
                
                {/* <CheckExam
                    examId={"40deeefd-c84f-11ee-b1d1-000d3ab074e6"}
                    orderNumber={25}
                /> */}

                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}

                    reduxVar={getExamError}
                />

                <Redux
                    showSuccess={false}
                    varId={REDUX_ERRORS}
                    
                    reduxVar={getAssignmentError}
                />
            </>
        }</div>
    )
}

export default GradeExam;
