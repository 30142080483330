
// react
import React, { useState } from 'react';

// redux
import { useSelector } from 'react-redux';

// routing
import { useHistory, useLocation } from 'react-router-dom';

// styling
import "./assets/MyProfile.scss";

// classes
import tab from "../../components/PageNav/services/classes/tab";

// services
import handleEnvironment from '../../services/handleEnvironment/handleEnvironment';

// components local
import PersonalInfo from './components/PersonalInfo/PersonalInfo';
import Password from './components/Password/Password';

// components
import PageNav from "../../components/PageNav/PageNav";
import Loader from '../../components/Loader';
import Settings from './components/Settings/Settings';

// constants
import { UVA_ENV } from '../../services/handleEnvironment/constants';

const MyProfile = () => {

    const user = useSelector(state => state.userReducer);

    // routing
    let history = useHistory();
    if(!user?.user){
        history.push("/");
    }

    const [loading, toggleLoading] = useState(true);

    return (
        <div className="examPrepView">
            <div className="ExamPrepTitle">
                Profiel
                {loading ? <div className="MyProfileLoading"><Loader /></div> : null}
            </div>

            <PageNav 
                initalTab="profile"
                updateURL={true}
                tabs={
                    handleEnvironment() === UVA_ENV ? 
                    [
                        new tab({
                            id: 'profile',
                            title: 'Profiel Informatie',
                            component: <PersonalInfo
                                loading={loading}
                                toggleLoading={toggleLoading}
                            />
                        }),
                        new tab({
                            id: 'settings',
                            title: 'Instellingen',
                            component: <Settings
                                loading={loading}
                                toggleLoading={toggleLoading}
                            />
                        }),
                    ] : [
                        new tab({
                            id: 'profile',
                            title: 'Profiel Informatie',
                            component: <PersonalInfo
                                loading={loading}
                                toggleLoading={toggleLoading}
                            />
                        }),
                        // new tab({
                        //     id: 'password',
                        //     title: 'Wachtwoord Aanpassen',
                        //     component: <Password />
                        // })
                    ]
                }
            />

        </div>
    )
}

export default MyProfile;
