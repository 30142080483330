
// services
import axios from "../services/Axios";
import authHeader from "../services/authHeader";
import handleResponse from "../services/handleResponse";

const reviewFeedbackAPI = {
    postThumbsUp,
    postThumbsDown,
    deleteReview,
}

async function postThumbsUp(aiEvaluationId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `reviewFeedback/${aiEvaluationId}/thumbsup`, 
            {}, 
            {
                headers: auth.payload
            }
        )
    );
    return responseObj;
}

async function postThumbsDown(aiEvaluationId) {
    const auth = await authHeader();

    const responseObj = await handleResponse(
        axios.post(
            `reviewFeedback/${aiEvaluationId}/thumbsdown`, 
            {}, 
            {
                headers: auth.payload
            }
        )
    );
    return responseObj;
}

async function deleteReview(aiEvaluationId) {
    const auth = await authHeader();
    
    const responseObj = await handleResponse(
        axios.delete(
            `reviewFeedback/${aiEvaluationId}/thumbsdown`, 
            {
                headers: auth.payload
            }
        )
    );
    
    return responseObj;
}

export default reviewFeedbackAPI;
