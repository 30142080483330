
import { BAD_REQUEST, SUCCESS } from "../../../../../../services/errors/constants";

const validateTrainingOnDataAllowed = (firstName) => {

    switch (firstName) {
        case "true":
            return SUCCESS;
        case "false":
            return SUCCESS;
        case true:
            return SUCCESS;
        case false:
            return SUCCESS;
        default:
            return BAD_REQUEST;
    }
}

export default validateTrainingOnDataAllowed;
