
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// styling
import "./Settings.scss";

// classes
import errorClass from '../../../../services/errors/classes/errorClass';
import CustomErrorsManager from '../../../../services/errors/classes/CustomErrorsManager';
import ClientData from '../../../../services/classes/clientData/ClientData';

// services
import networking from '../../../../services/handleNetworking/networking/networking';
import authApi from '../../../../services/Networking/authentication/authentication';
import handleEnvironment from '../../../../services/handleEnvironment/handleEnvironment';
import convertError from '../../../../services/errors/convertError';

// utils
import validateGenerateFeedbackAllowed from './utils/validation/generateFeedbackAllowed';
import validateTrainingOnDataAllowed from './utils/validation/trainingOnDataAllowed';

// components
import ProfilePic from '../ProfilePic/ProfilePic';
import Redux from '../../../../components/Redux/Redux';
import SettingsInput from "./components/SettingsInput/SettingsInput";
import Submit from '../../../../components/input/elements/Submit';

// constants
import { BAD_REQUEST, NOT_FOUND, SUCCESS } from '../../../../services/errors/constants';
import { REDUX_ERRORS } from '../../../../components/Redux/services/constants';
import { UVA_ENV } from '../../../../services/handleEnvironment/constants';
import { ALLOW_GENERATED_FEEDBACK_KEY, ALLOW_TRAINING_KEY } from './utils/constants';

const Settings = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // state
    const [editting, toggleEdit] = useState(false);

    // data
    const [userSettings, updateUserSettings] = useState(null);
    const updateSettings = (sentSettings) => {
        updateUserSettings(new ClientData({
            sent: sentSettings
        }));
    }

    // errors
    const [fetchError, updateFetchError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    // input errors
    const [userImage, updateuserImage] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const fetchUserSettings = async() => {
        const returned = await networking({
            toggleLoading: props.toggleLoading,

            api: authApi.getUserSettings,
            apiParams: user?.user?.userId,

            updateContent: updateSettings,

            updateError: updateFetchError,
            customErrors: (errorCode) => new CustomErrorsManager(
                {
                    [NOT_FOUND]: {
                        title: "Instellingen nog niet ingevuld."
                    },
                }
            ).getError(errorCode),
        });
        if (returned && returned.status === NOT_FOUND) {
            updateUserSettings(new ClientData({
                post: {
                    [ALLOW_GENERATED_FEEDBACK_KEY]: null,
                    [ALLOW_TRAINING_KEY]: null,
                }
            }));
        }
    }

    useEffect(() => {
        updateuserImage("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAjCAYAAADxG9hnAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHoSURBVHgBzViLccIwDFW4DpARskGzQbNB2aDZoGxANgidIHSCjgDdgA2cDegGqoRtSHv+yCYcvDuRI5GeX2xZUVJABhCxokNNxsfSnP4hG8kORVGMcCvw4GRrMoVxsM/GCJ5VwBbzMVwtiAjeyY54PZhjBTmgwB7nR58qYhASK+P7hbLcYQxSEWsBGU9144hthYL6mIgWZagCHDXK8mrpI6iEdxOdWvLpBDwstnQFS7doLRBSC7k612xIUQqElEKu86wsTGwDckSFgC79Uq52KuQN5FgKfKLLN8Hr6TdhGi1UbHlQXlMsykWiekZF1rvEmJsaQL40FvVTRhCjJWto0A86HiwZ2XsmX5Ur5BRMlvbsCHAt4EHwMEJ4aUZIw57sG3RunNtC1BWXE7ghe4G02jQyQSPYXlwBOxRUVQu8dHaSB2AjqSM7vKLVM4J2kTFK6+xzPMBMCIyxmzqtPE4NzAT0p0DL1+2u2YJ+L7klfPm1//MP3c2MwhneTdDfdA0uZ07a49xiAiL8vHRhiW6onHxBnRfKw9nGgjfox4CC2TECQq1n9z+m8BBtIdws8bbm6rqHS5JXoJ/AzxCuqp9UjVuQIjIzudhADlDXl/u++07E3P9rgEMQL5cSDM4+XYqAAjKAN/hi9At8VvDeLKfEigAAAABJRU5ErkJggg==");
        fetchUserSettings();
    }, []);

    const afterSuccessfullySaved = async() => {
        fetchUserSettings();
        toggleEdit(false)
    }

    return (
        <div
            className="settingsView"
        >
            <div className='profile-pic'>
                <ProfilePic
                    firstName={user?.user?.fullName || ""} 
                    lastName="" 
                    email={user?.user?.email || ""}
                    userImage = {userImage || ""} 
                />
            </div>
            
            <div className='profile-form'>
                {
                    editting ? 
                    <SettingsInput
                        clientData={userSettings}
                        afterSaveSuccess={afterSuccessfullySaved}
                        cancle={() => toggleEdit(false)}
                    />
                    :
                    <>
                        <div className='settingsUserInput' >
                            <div className='form-container'>
                                <div class="textInput">
                                    <div class="textInputTitle">FEEDBACK GENEREREN MAG</div>
                                    <div class="textInputTitle textLight">{props.loading ? "" : !userSettings?.sent ? "Niet ingevuld" : userSettings?.sent?.[ALLOW_GENERATED_FEEDBACK_KEY] === true ? "JA" : "NEE"}</div>
                                </div>
                            </div>
                            <div className='form-container'>
                                <div class="textInput">
                                    <div class="textInputTitle">TRAINEN VAN (AI) MODELLEN MAG MET MIJN DATA</div>
                                    <div class="textInputTitle textLight">{props.loading ? "" : !userSettings?.sent ? "Niet ingevuld" : userSettings?.sent?.[ALLOW_TRAINING_KEY] === true ? "JA" : "NEE"}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Submit
                                value="Instellingen Aanpassen"
                                onClick={()=>toggleEdit(true)}
                            />
                        </div>
                    </>
                }
            </div>

            <div className='useSettingsFeedbackGenerationWarning'>
                <div className="title">
                    Voorwaarden voor Gebruik van Feedback Genereren
                </div> 
                <div className="subTitle">
                    Limitaties
                </div>
                <div className="body">
                    Wij gebruiken een Large Language Model (LLM) om feedback en punten te genereren voor elke vraag op basis van jouw antwoorden op oefenvragen. 
                    Dit systeem, gebaseerd op kunstmatige intelligentie, produceert automatisch feedback zonder menselijke tussenkomst. Het model kan onjuiste feedback of punten opgeven. 
                    Zorg er dus voor dat je de gegenereerde feedback en punten niet blindelings vertrouwt.
                </div>

                {
                    handleEnvironment() === UVA_ENV ?
                    <>
                        <div className="subTitle">
                            Data
                        </div>
                        <div className="body">
                            Jouw antwoorden worden verwerkt op servers van Microsoft, in overeenstemming met de contractuele afspraken van de UvA. Jouw gegevens worden niet opgeslagen door externe partijen en niet zonder toestemming gebruikt voor verdere training van het model.
                        </div>
                    </> : null
                }

                <div className="subTitle">
                    Wachttijd
                </div>
                <div className="body">
                    Het genereren van punten en feedback kan tot ongeveer 50 seconden duren.
                </div>

                <div className="subTitle">
                    Contact
                </div>
                <div className="body">
                    Heb je vragen? Stuur dan een e-mail naar olaf@etalia.nl of een WhatsApp-bericht naar 0623991946.
                </div>
            </div>

            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={fetchError}
            />
        </div>
    )
}

export default Settings;
