import { useEffect, useState } from "react";

// routing
import { useHistory } from 'react-router-dom';

// styles
import "./AssignedExam.scss";

// services
import networking from "../../../../../../../../services/handleNetworking/networking/networking";

// components
import InfoItem from "../../../../../../../../components/InfoItem/InfoItem";
import Loader from "../../../../../../../../components/Loader";
import examsApi from "../../../../../../../../services/Networking/exams/exams";

// constants
import { PUBLISHED } from "../../../../../../../../services/constants/stateConstants";
import { SAVE, SUBMIT } from "../../../../../../../Exams/Views/TakeExam/constants/constants";
import Delete from "../../../../../../../../components/Delete/Delete";
import assignedExamApi from "../../../../../../../../services/Networking/assignedExam/assignedExam";

const AssignedExam = (props) => {

    // routing
    let history = useHistory();

    // states
    const [loading, toggleLoading] = useState(true);
    const [initDelete, toggleInitDelete] = useState(false);

    // data
    const [examData, updateExamData] = useState();

    // errors
    const [getExamError, updateGetExamError] = useState(null);

    const fetchAssignedExam = async() => {
        const returned = await networking({
            updateContent: updateExamData,
            toggleLoading: toggleLoading,
            errorOnSuccess: false,

            api: examsApi.getExamByExamId,
            apiParams: { examId: props.assignmnetItem.itemUUID, state: PUBLISHED },

            updateError: updateGetExamError,
        });
    }

    useEffect(() => {
        fetchAssignedExam();
    }, []);

    return (
        <div
            className={`AssignedExamView ${props.assignmnetItem.state === SUBMIT ? "AssignedExamViewSubmitted" : ""}`}
        >
            <div
                className="AssignedExamSection"
            >
                <InfoItem
                    title="Tentamen title"
                    value={props.assignmnetItem?.name}
                />
                <InfoItem
                    title="Opracht type"
                    value="Tentamen"
                />
                {
                    !examData ? null :
                    <>
                        <InfoItem
                            title="Hoeveelheid vragen"
                            value={examData?.questions?.length}
                        />
                    </>
                }
                {
                    props.assignmnetItem.state === null ? null :
                    <InfoItem
                        title="Ingeleverd"
                        value={props.assignmnetItem.state === SAVE ? "Begonnen" : props.assignmnetItem.state === SUBMIT ? "Ingeleverd" : ""}

                        infoItemAditionalStyle={props.assignmnetItem.state === SUBMIT ? "AssignedExamSubmitted" : ""}
                    />
                }
                {
                    props.assignment.getCurrentValue("isAdmin") === true ? 
                    <div
                        className="AssignedExamToExamButton"
                        onClick={() => history.push(`/opdrachten/${props.assignment.id}/tentamen/${props.assignmnetItem.itemUUID}/Nakijken`)}
                    >
                        Tentamen nakijken
                    </div>
                    : null
                }

                {loading ? <Loader /> : null}
            </div>

            <div className="AssignedExamSection AssignedExamSectionLast" >{
                props.assignment.getCurrentValue("isAdmin") === true ? 
                <>
                    <div
                        className="AssignedExamToExamButton"
                        onClick={() => history.push(`/opdrachten/${props.assignment.id}/tentamen/${props.assignmnetItem.itemUUID}/Aanpassen`)}
                    >
                        Tentamen aanpassen
                    </div>
                    <div className="AssignedExamHoldDelete">
                        <Delete
                            api={assignedExamApi.deleteAssignmentItemFromAssignment}
                            id={props.assignmnetItem?.id}

                            runOnSuccess={() => props.getAssignment()}
                        />
                    </div>
                </> :
                <>
                    <div
                        className="AssignedExamToExamButton"
                        onClick={() => history.push(`/opdrachten/${props.assignment.id}/tentamen/${props.assignmnetItem.itemUUID}/Maken`)}
                    >{
                        props.assignmnetItem.state === SUBMIT ?
                        "Tentamen nog een keer maken"
                        :
                        "Tentamen maken"
                    }</div>
                    {
                        props.assignment?.getCurrentValue("canViewFeedback") === true && props.assignmnetItem.state === SUBMIT ? 
                        <div
                            className="AssignedExamToExamButton"
                            onClick={() => history.push(`/opdrachten/${props.assignment.id}/tentamen/${props.assignmnetItem.itemUUID}/Inkijken`)}
                        >
                            Feedback bekijken
                        </div> : null
                    }
                </>
            }</div>

            {/* {
                !examData ? null :
                <>
                    <div
                        className="AssignedExamSection"
                    >
                        <InfoItem
                            title="Studenten gestart"
                            value={examData?.studentsStarted}
                        />
                        <InfoItem
                            title="Studenten toegezegd toestemming AI"
                            value={examData?.consentToUseAI}
                        />
                        <InfoItem
                            title="Tentamens ingeleverd"
                            value={examData?.submittedExams}
                        />
                        <InfoItem
                            title="Vragen ingeleverd"
                            value={examData?.submittedQuestions}
                        />
                    </div>

                    <div
                        className="AssignedExamSection"
                    >
                        <InfoItem
                            title="Vragen nagekeken door AI"
                            value={examData?.questionsCheckByAI}
                        />
                        <InfoItem
                            title="Vragen nagekeken door docenten"
                            value={examData?.questionsCheckByTeacher}
                        />
                        <InfoItem
                            title="Vragen nagekeken door studenten"
                            value={examData?.questionsCheckedByStudents}
                        />
                    </div>
                </>
            } */}
        </div>
    )
}

export default AssignedExam;
