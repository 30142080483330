
// react
import { useState } from "react";

// redux
import { useDispatch, useSelector } from "react-redux";

// styles
import "./SettingsInput.scss";

// classes
import errorClass from "../../../../../../services/errors/classes/errorClass";

// services
import authApi from "../../../../../../services/Networking/authentication/authentication";
import convertError from "../../../../../../services/errors/convertError";
import CustomErrorsManager from "../../../../../../services/errors/classes/CustomErrorsManager";
import networking from "../../../../../../services/handleNetworking/networking";

// utils
import validateGenerateFeedbackAllowed from "../../utils/validation/generateFeedbackAllowed";
import validateTrainingOnDataAllowed from "../../utils/validation/trainingOnDataAllowed";

// components
import Boolean from "../../../../../../components/input/Boolean/Boolean";
import Loader from "../../../../../../components/Loader";
import Submit from "../../../../../../components/input/elements/Submit";
import Redux from "../../../../../../components/Redux/Redux";

// constants
import { ALLOW_GENERATED_FEEDBACK_KEY, ALLOW_TRAINING_KEY } from "../../utils/constants";
import { BAD_REQUEST, INTERNAL_ERROR, NO_CONTENT, NOT_FOUND, SUCCESS } from "../../../../../../services/errors/constants";
import { REDUX_ERRORS } from "../../../../../../components/Redux/services/constants";

const SettingsInput = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // states
    const [loading, toggleLoading] = useState(false);

    // errors
    const [postError, updatePostError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    // input errors
    const [allowGeneratedFeedbackError, updateAllowGeneratedFeedbackError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [allowTrainingError, updateAllowTrainingError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const validateUserInput = (userSettingsData) => {
        if (!userSettingsData) {
            return false;
        }
        let validInput = true;
        const genfeedbackAllowedValid = validateGenerateFeedbackAllowed(userSettingsData[ALLOW_GENERATED_FEEDBACK_KEY]);
        const genfeedbackAllowedErrorTemp = convertError({
            errorCode: genfeedbackAllowedValid,
            updateLocally: updateAllowGeneratedFeedbackError,
            customErrors: (errorCode) => new CustomErrorsManager(
                {
                    [NOT_FOUND]: {
                        title: "Token niet gevonden."
                    },
                    [BAD_REQUEST]: {
                        title: "Dit velt moet ingevult zijn."
                    },
                }
            ).getError(errorCode),
        });

        if (!genfeedbackAllowedErrorTemp) {
            validInput = false;
        } else {
            if (genfeedbackAllowedErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                updateAllowGeneratedFeedbackError(genfeedbackAllowedErrorTemp);
                validInput = false;
            }
        }

        const trainingAllowedValid = validateTrainingOnDataAllowed(userSettingsData[ALLOW_TRAINING_KEY]);
        const trainingAllowedErrorTemp = convertError({
            errorCode: trainingAllowedValid,
            updateLocally: updateAllowTrainingError,
            customErrors: (errorCode) => new CustomErrorsManager(
                {
                    [NOT_FOUND]: {
                        title: "Token niet gevonden."
                    },
                    [BAD_REQUEST]: {
                        title: "Dit velt moet ingevult zijn."
                    },
                }
            ).getError(errorCode),
        });

        if (!trainingAllowedErrorTemp) {
            validInput = false;
        } else {
            if (trainingAllowedErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                updateAllowTrainingError(trainingAllowedErrorTemp);
                validInput = false;
            }
        }
        return validInput;
    }

    const saveSettings = async() => {
        const userSettingsData = props.clientData.post ?? props.clientData.put;
        if (!userSettingsData) {
            // nothing changed from sent
            props.afterSaveSuccess();
        }

        // validation
        const validInput = validateUserInput(userSettingsData);
        if (validInput === false) {
            return;
        }

        // storing
        const returned = await networking({
            toggleLoading: toggleLoading,

            api: props.clientData.post ? authApi.postUserSettings : authApi.putUserSettings,
            apiParams: {
                userId: user?.user?.userId,    
                data: {
                    [ALLOW_GENERATED_FEEDBACK_KEY]: userSettingsData?.[ALLOW_GENERATED_FEEDBACK_KEY],
                    [ALLOW_TRAINING_KEY]: userSettingsData?.[ALLOW_TRAINING_KEY],
                }
            },

            updateError: updatePostError,
            customErrors: (errorCode) => new CustomErrorsManager(
                {
                    [INTERNAL_ERROR]: {
                        title: "Er is iets misgegaan bij het opslaan van je instellingen. Probeer het later opnieuw."
                    },
                    [NOT_FOUND]: {
                        title: "Er is iets misgegaan bij het opslaan van je instellingen. Probeer het later opnieuw."
                    }
                }
            ).getError(errorCode)
        });

        // action on success
        if (returned && returned.status === SUCCESS) {
            if (props.afterSaveSuccess) {
                props.afterSaveSuccess();
            }
        } else {
            if (props.afterSaveSettingsFailure) {
                props.afterSaveSettingsFailure();
            }
        }
    }

    return (
        <div className="SettingsInputView" >
            <div className="inputView" >
                <Boolean
                    title="Feedback genereren"

                    valueKey={ALLOW_GENERATED_FEEDBACK_KEY}
                    clientData={props.clientData}

                    error={allowGeneratedFeedbackError}
                    updateError={updateAllowGeneratedFeedbackError}
                />

                <Boolean
                    title="Mijn antwoorden, inclusief de feedback en punten die ik opgeef tijdens het nakijken van mijn eigen werk, mogen gebruikt worden voor het trainen van modellen (niet nodig voor het ontvangen van gegenereerde feedback)"

                    valueKey={ALLOW_TRAINING_KEY}
                    clientData={props.clientData}

                    error={allowTrainingError}
                    updateError={updateAllowTrainingError}
                />
            </div>

            <div className="submitButtonView">
                <div className="marginRight">
                    {
                        loading ? <Loader /> :
                        <Submit
                            value={props.submitButtonText ?? "Opslaan"}
                            onClick={saveSettings}
                        />
                    }
                </div>
                <div className="marginLeft">
                    <Submit
                        value="Annuleren"
                        onClick={()=>props.cancle()}
                    />
                </div>
            </div>

            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={postError}
            />
        </div>
    )
}

export default SettingsInput;
