
import React, { useState, useEffect } from 'react';

// style
import "./questionInfo.scss";

// classes
import option from "../../../../../../components/input/elements/Select/classes/option";

// services
import access from '../../../../../../services/access/accessCheck/access';

// components
import SelectInput from "../../../../../../components/input/Select";

// constants
import { EDIT} from "../../../../services/tabConstants";
import { PRACTICE_EXAM, FINAL_EXAM } from "../../../../services/translateExamCategory/categoryConstants";
import { ASSAY_QUESTION, MULTIPLE_CHOICE_QUESTION } from "../../../../services/constants/typeConstants";
import { CATEGORY, TYPE } from '../../../../services/constants/questionHubKeys';
import { ONE_CORRECT } from '../../../../services/constants/answerHubKeys';
import { DRAFT, PUBLISHED } from '../../../../../../services/constants/stateConstants';
import { EDITOR } from '../../../../../../services/access/constants';

const Info = (props) => {

    // add custom update func!
    const updateSelected = (valueKey, newValue) => {

        props.questionHub.updateProperty(valueKey, newValue);
        
        // make sure states are changed
        if (valueKey === TYPE) {
            props.questionHub.updateAnswersToType();

            if (newValue === MULTIPLE_CHOICE_QUESTION) {
                // make answers text editable
                props.toggleEditMCAnswers(true);
            }

            props.updateQuestionType(newValue);
            props.updateAnswers();
        }

        if (valueKey === ONE_CORRECT) {
            
            if (newValue) {
                // make only first correct, correct
                let correctFound = false;
                
                props.answerhubs.map((answerHub) => {
                    if (answerHub.getCurrentValue("correct")) {
                        if (correctFound) {
                            answerHub.updateProperty("correct", false);
                            // add falsed answer to former correctAnswers list of question
                            props.questionHub.correctAnswers = [...props.questionHub.correctAnswers, answerHub.number]
                        } else {
                            correctFound = true;
                        }
                    }
                });

            } else {
                props.answerhubs.map((answerHub) => {

                    // check if in correctAnswers list or currently correct
                    if (
                        props.questionHub.correctAnswers.find((correctAnswer) => correctAnswer === answerHub.number) ||
                        answerHub.getCurrentValue("correct")
                        ) {
                        
                        // make answer correct
                        answerHub.updateProperty("correct", true);
                    }

                })

                props.questionHub.correctAnswers = [];
            }

            props.toggleEditMCAnswers(false);
            
            props.updateoneCorrectAnswer(newValue);
            props.updateAnswers();
        }
    }

    return (
        <div className="QuestionInfoHolder">
            
            <div className="QuestionInfoSubHolder">
                <div className="QuestionInfoItem" >
                    {"Vraag category: "}
                    {
                        props.examView === EDIT ? 
                        <SelectInput
                            options={[
                                new option({
                                    value: 0,
                                    title: "."
                                }),
                                new option({
                                    value: PRACTICE_EXAM,
                                    title: "Oefenvraag"
                                }),
                                new option({
                                    value: FINAL_EXAM,
                                    title: "Eindexamenvraag"
                                }),
                            ]}

                            valueKey={CATEGORY}
                            
                            // use phoney data class if custom updated
                            dataClass={props.questionHub.getQuestion()}

                            // use custom update if no edited version of question
                            customUpdateFunc={updateSelected}

                            useBaseStyle={true}
            
                            errorClass={props.categoryError}
                            updateError={props.updateCategoryError}
                        />
                        :
                        props.questionHub.getCurrentValue(CATEGORY) === PRACTICE_EXAM ? "Oefenvraag" : "Eindexamenvraag"
                    }
                    
                </div>
                <div className="QuestionInfoItem" >
                    {"Vraag type: "}
                    {
                        props.examView === EDIT ? 
                        <SelectInput
                            options={[
                                new option({
                                    value: 0,
                                    title: "."
                                }),
                                new option({
                                    value: ASSAY_QUESTION,
                                    title: "Assay vraag"
                                }),
                                new option({
                                    value: MULTIPLE_CHOICE_QUESTION,
                                    title: "Multiple choice"
                                }),
                            ]}

                            valueKey={TYPE}

                            // use phoney data class if custom updated
                            dataClass={props.questionHub.getQuestion()}

                            customUpdateFunc={updateSelected}
                            
                            useBaseStyle={true}
            
                            errorClass={props.typeError}
                            updateError={props.updateTypeError}
                        />
                        :
                        props.questionHub.getCurrentValue(TYPE) === ASSAY_QUESTION ? "Open vraag" : "Multiplechoice"
                    }
                </div>

                {
                    props.questionHub.getCurrentValue(TYPE) !== MULTIPLE_CHOICE_QUESTION ? null :
                    <div className="QuestionInfoItem" >{
                        props.examView === EDIT ?
                            <SelectInput
                                options={[
                                    new option({
                                        value: true,
                                        title: "Eén correct antwoord"
                                    }),
                                    new option({
                                        value: false,
                                        title: "Meerdere correcte antwoorden"
                                    }),
                                ]}

                                valueKey={ONE_CORRECT}

                                // use phoney data class if custom updated
                                // dataClass={props.questionHub.getQuestion()}
                                dataClass={{
                                    oneCorrect: props.questionHub.getCurrentValue("oneCorrect")
                                }}

                                customUpdateFunc={updateSelected}
                                
                                useBaseStyle={true}
                
                                // errorClass={props.categoryError}
                                // updateError={props.updateCategoryError}
                            />
                        
                        : props.questionHub.oneCorrect() ? "Eén correct antwoord" : "Meerdere correcte antwoorden"
                    }</div>
                }

            </div>

            <div className="QuestionInfoSubHolder">
                <div className="QuestionInfoItem" >
                    Editors: {props.editors}
                </div>
            </div>

            {
                props.user ? access({
                    accessLevel: EDITOR,
                    user: props.user
                }) && props.questionHub.questionId ?
                <div className="QuestionInfoSubHolder">
                    <div className="QuestionInfoItem" >
                        Status: {props.state === DRAFT ? "Draft" : "Gepubliceerd" }
                    </div>
                </div> : "" : ""
            }
        </div>
    )
}

export default Info;
