
import React, { useState, useEffect } from 'react';

// routing
import { useParams, useHistory, useRouteMatch, useLocation, Redirect } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// networking
import takenExamsApi from "../../../../services/Networking/takenExams";

// services local
import fetchExamOrder from "../../services/networking/takenExams/fetchExamOrder";
import fetchTakenExam from "../../services/networking/takenExams/fetchTakenExams";
import examInfoConstructor from "../../components/ExamInfo/services/constructor";
import formatCheckedAnswers from "./services/formatCheckedAnswers";

// services
import convertError from "../../../../services/errors/convertError";
import access from '../../../../services/access/accessCheck/access';

// errors local
import handInErrors from "../../services/errors/handInErrors";

// style
import "./CheckExam.scss";

// classes local
import StickyButtonClass from "../../components/StickyButtons/StickyButtonClass";

// classes
import buttonClass from '../../../../components/Alert/services/classes/ButtonClass/buttonClass';

// services
import examEvaluation from '../../../../services/Networking/examEvaluation/examEvaluation';
import handleEnvironment from '../../../../services/handleEnvironment/handleEnvironment';
import createExamURL from '../../../../services/createExamURL/createExamURL';
import adminView from '../../../../services/uva/adminView';

// components local
import Question from "../../components/Question";
import ExamInfo from "../../components/ExamInfo";
import StickyButtons from "../../components/StickyButtons";

// components
import Title from "../../components/Title";
import Loader from '../../../../components/Loader';
import List from "../../../../components/List";
import Breadcrumb from "../../../../components/Breadcrumb";
import Alert from '../../../../components/Alert';
import LocalModal from '../../../../components/LocalModal';
import ExamPointsGraph from '../../components/Statistics/ExamPointsGraph';

// constants
import { SUCCESS, NO_RESPONSE, MAIN_OPERATION_SUCCESS } from "../../../../services/errors/constants";
import { CHECK} from "../../services/tabConstants";
import { ADMIN, EDITOR, STUDENT, TEACHER } from '../../../../services/access/constants';
import { CLOSE, TAKE_AGAIN } from './services/constants';
import { ETALIA_ENV, UVA_ENV } from '../../../../services/handleEnvironment/constants';

const CheckExam = (props) => {

    // routing
    let history = useHistory();
    let { url } = useRouteMatch();
    // const navigate = useNavigate();

    // states
    const [showQuestionInfo, toggleShowQuestionInfo] = useState(false);

    // data
    const [report, updateReport] = useState([]);

    // id's
    let { subjectId, bookId, tijdvakID, kaID, examID, oderNumb } = useParams();
    const examId = props.examId || examID?.replace("e-", "");
    const orderId = props.orderNumber || parseInt(oderNumb);
    
    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    const [examView, updateExamView] = useState(CHECK);

    const [reRender, triggerReRender] = useState(false);
    const [orderName, updateOrderName] = useState();

    const [examClass, updateExamClass] = useState(null);
    const [takenExams, updateTakenExams] = useState([]);
    const [questionHubs, updateQuestionHubs] = useState([]);

    const [takenExam, updateTakenExam] = useState(null);

    // loading
    const [loading, toggleLoading] = useState(true);
    const [loadingDifferentTakenExam, toggleLoadingDifferentTakenExam] = useState(false);
    const [loadingSaving, toggleLoadingSaving] = useState(false);
    const [loadingGenerateFeedback, toggleLoadingGenerateFeedback] = useState(false);

    // modals
    const [showAutogradingConsent, toggleShowAutogradingConsent] = useState(false);
    const [showExamInfo, toggleShowExamInfo] = useState(true);

    // popup before unsaved closing
    const [showUnsavedWarning, toggleShowUnsavedWarning] = useState(false);

    useEffect(() => {
        fetchTakenExamOrder();
    }, [examClass]);
    
    const fetchTakenExamOrder = async() => {
        if (examClass && examClass.examUUID) {
            const returnedOrders = await fetchExamOrder({
                examId: examClass.examUUID,
                
                handleApiReturn: handleApiReturn,
    
                updateOrder: updateTakenExams, 
            });
        }
    }

    useEffect(() => {

        if (orderId) {
            if (!access({
                accessLevel: [STUDENT, ADMIN, EDITOR, TEACHER],
                user: user
            })) {
                // go to view page
                history.replace(`/geschiedenis/${tijdvakID}/${kaID}/${examID}`);
            }

            toggleLoading(true);
            toggleLoadingDifferentTakenExam(true);
            fetchingTakenExam();
            // namingOrderNumber();
        }
    }, [orderId, reRender, user]);

    const fetchingTakenExam = async() => {
        toggleLoading(true);

        const returnedExamInfo = await fetchTakenExam({
            examId: examId,
            orderNumb: orderId,
            
            handleApiReturn: handleApiReturn,

            updateExamClass: updateExamClass, 
            updateQuestionHubs: updateQuestionHubs, 
        });

        toggleLoadingDifferentTakenExam(false);
        toggleLoading(false);

    }

    const handleApiReturn = async(returned, handingIn) => {
        if (handingIn) {
            dispatch(globalErrorsActions.emptyErrorList());
        }

        if (!returned) {

            dispatch(globalErrorsActions.emptyErrorList());

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_RESPONSE,
                    customErrors: null
                })
            ));

            // something weard happend
            toggleLoading(false);
            return false;
        }

        if (returned.status === SUCCESS || returned.status === MAIN_OPERATION_SUCCESS) {

            if (handingIn) {
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: returned.status,
                        customErrors: handInErrors
                    })
                ));
            }

            toggleLoading(false);
            return true;

        } else {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status
                })
            ));
        }

        toggleLoading(false);
        return false;

    }

    const renderQuestion = (i, questionHub) => {

        if (!questionHub) {
            return null;
        }

        return (
            <Question
                key={questionHub.questionId}

                examView={examView}
                triggerReRender={triggerReRender}

                questionHub={questionHub}

                showQuestionInfo={showQuestionInfo}
                toggleShowQuestionInfo={toggleShowQuestionInfo}
            />
        )
    }

    const savePutAnswers = async(answerHubsPut) => {
        const returned = await takenExamsApi.postCheckedQuestionByExamId({
            examId: examId, 
            order: orderId, 
            checkedQuestions: answerHubsPut
        });

        handleApiReturn(returned, true);

    }

    const saving = async() => {

        toggleLoadingSaving(true);

        // getting all edited hubs
        const checkedAnswers = formatCheckedAnswers(
            examId,
            questionHubs
        );
        let takenExamIsUpdated = false;

        if (checkedAnswers.length > 0) {
            await savePutAnswers(checkedAnswers);
            takenExamIsUpdated = true;
        }

        if (takenExamIsUpdated) {
            await fetchingTakenExam();
        }

        toggleLoadingSaving(false);

    }

    const savePressed = () => {
        saving();
    }

    const checkForChanges = () => {
        const checkAnswers = formatCheckedAnswers(
            examId,
            questionHubs
        );
        if (checkAnswers.length > 0) {
            return true;
        }
        return false;
    }
    const [lastClickedClose, updateLastClickedClose] = useState(null);
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (checkForChanges() === true) {
                event.preventDefault();
                event.returnValue = "U heeft niet-opgeslagen wijzigingen! Weet u zeker dat u wilt vertrekken?";
                
                updateLastClickedClose(null);
                toggleShowUnsavedWarning(true);
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => window.removeEventListener('beforeunload', handleBeforeUnload);

    }, [examId, questionHubs]);
    const updateCurrentOrderFunc = ({takenExamId, forceClose}) => {

        if (takenExamId) {
            updateLastClickedClose(takenExamId);
        }

        // check for unsaved changes
        const stopClosing = forceClose ? false : checkForChanges();
        if (stopClosing) {
            toggleShowUnsavedWarning(true);
            return;
        }
        takenExamId = takenExamId ? takenExamId : forceClose;
        
        history.push(`/geschiedenis/${tijdvakID}/${kaID}/${examID}/${takenExamId}/Examen-Nakijken`);
        toggleShowUnsavedWarning(false);

    }

    const close = ({forceClose}) => {

        updateLastClickedClose(CLOSE);

        // check for unsaved changes
        const stopClosing = forceClose ? false : checkForChanges();
        if (stopClosing) {
            toggleShowUnsavedWarning(true);
            return;
        }

        if (handleEnvironment() === UVA_ENV) {
            history.push(`/tentamen-voorbereidingen`);
            return;
        }

        // Get the current pathname
        const currentPath = window.location.pathname;
        if (currentPath.startsWith("/examen-voorbereidingen")) {
            history.push(`/examen-voorbereidingen`);
            return;
        }
        // check if unsaved changes - `/geschiedenis/t-${topicId}/st-${subTopicId}/e-${examId}`
        history.push(`/geschiedenis/${tijdvakID}/${kaID}/${examID}`);
    }

    const takeAgain = ({forceClose}) => {
        updateLastClickedClose(TAKE_AGAIN);

        // check for unsaved changes
        const stopClosing = forceClose ? false : checkForChanges();
        if (stopClosing) {
            toggleShowUnsavedWarning(true);
            return;
        }

        const newURL = createExamURL({
            subjectId,
            bookId,
            tijdvakID,
            kaID,
            examID,

            examPage: "Examen-Maken",
        });

        history.push(newURL);
    }

    const feedbackGeneren = async() => {
        if (handleEnvironment() === ETALIA_ENV) {
            return;
        }
        toggleShowAutogradingConsent(false);
        toggleLoadingGenerateFeedback(true);
        const returned = await examEvaluation.postExamEvaluation(examId, orderId);
        handleApiReturn(returned, true);

        toggleLoadingGenerateFeedback(false);
    }

    const generateButtonList = () => {
        let buttons = [];
    
        // Conditionally add the "Feedback genereren" button if not in UVA_ENV and depending on loadingGenerateFeedback
        // if (handleEnvironment() === UVA_ENV || adminView()) {
        //     buttons.push(new StickyButtonClass({
        //         title: loadingGenerateFeedback ? <Loader /> : "Feedback genereren",
        //         onClickFunc: loadingGenerateFeedback ? null : () => toggleShowAutogradingConsent(true),
        //         link: null,
        //     }));
        // }
    
        // add the "Opslaan" button
        buttons.push(new StickyButtonClass({
            title: loadingSaving ? <Loader /> : "Opslaan",
            onClickFunc: loadingSaving ? null : savePressed,
            link: null,
        }));
    
        // Add "Opnieuw maken" button
        buttons.push(new StickyButtonClass({
            title: "Opnieuw maken",
            onClickFunc: () => takeAgain({}),
            link: null,
        }));
    
        // Add "Sluiten" button
        buttons.push(new StickyButtonClass({
            title: "Sluiten",
            onClickFunc: () => close({}),
            link: null,
        }));
    
        return buttons;
    };

    return (
        <div className="checkExam" >

            <Breadcrumb />

            <StickyButtons
                
                // maybe make the buttons depend on if there are changes!
                buttons={generateButtonList()}
            />

            {
                loading && !examClass ? <Loader /> : !examClass ? null :
        
                <Title
                    title={`${examClass.title} - ${orderName ? orderName : ""}`}

                    showExamInfo={showExamInfo}
                    toggleShowExamInfo={toggleShowExamInfo}

                    loading={loading && examClass}
                />
            
            }

            {
                loading && !examClass ? null : !showExamInfo ? null : !examClass ? null :
                <ExamInfo
                    // this taken exam
                    takenExam={takenExam}

                    // exam info
                    infoColumns={examInfoConstructor({
                        tijdvakID: tijdvakID, 
                        kaID: kaID, 
                        examID:examID,
                        orderId: orderId,
                        examInfo: examClass,
                        editors: ["EtAlia"],
                        takenExams: takenExams,
                        updateTakenExam: updateCurrentOrderFunc
                    })}

                    updateCurrentOrderFunc={updateCurrentOrderFunc}

                    currentOrder={orderId}
                />
            }

            <ExamPointsGraph
                exam={examClass}
                takenExamUuId={examClass?.uuid}
            />

            {
                !questionHubs ? null :
                loadingDifferentTakenExam && questionHubs.length === 0 ? <Loader /> : 
                questionHubs.length === 0 ?
                "" :
                <List
                    items={questionHubs}
                    renderItem={renderQuestion}
                />
            }

            <LocalModal
                show={showUnsavedWarning}
                toggleShow={toggleShowUnsavedWarning}
                component={
                    <Alert
                        toggleShow={toggleShowUnsavedWarning}
                    
                        title="Onopgeslagen veranderingen"
                        description="Er zijn onopgeslagen veranderingen gededacteerd in de toets. Weet je zeker dat je wilt afsluiten?"
                    
                        buttonClasses={[
                            new buttonClass({
                                title: "Afsluiten annueleren",
                                buttonFunc: () => toggleShowUnsavedWarning(false),
                            }),
                            ...(lastClickedClose === null ? [] : [new buttonClass({
                                title: "Doorgaan zonder op te slaan",
                                buttonFunc: () => lastClickedClose === CLOSE ? close({forceClose: true}) : lastClickedClose === TAKE_AGAIN ? takeAgain({forceClose: true}) : updateCurrentOrderFunc({forceClose: lastClickedClose}),
                            })])
                        ]}
                    />
                }
            />

            <LocalModal
                show={showAutogradingConsent}
                toggleShow={toggleShowAutogradingConsent}
                component={
                    <Alert
                        toggleShow={toggleShowAutogradingConsent}
                    
                        title="Voorwaarden voor het gebruik van Large Language Models (LLM's) voor automatisch nakijken"
                        description="
                        Limitaties:\n
                        Wij gebruiken een Large Language Model (LLM) om feedback en punten te genereren voor elke vraag op basis van jouw antwoorden op het oefententamen. 
                        Dit systeem, gebaseerd op kunstmatige intelligentie, produceert automatisch feedback zonder menselijke tussenkomst. Het model kan onjuiste feedback of punten opgeven. 
                        Zorg er dus voor dat je de gegenereerde feedback en punten niet blindelings vertrouwt.
                        \n
                        \n
                        Data:\n
                        Jouw antwoorden worden verwerkt op servers van Microsoft, in overeenstemming met de contractuele afspraken van de UvA. Jouw gegevens worden niet opgeslagen door externe partijen en niet gebruikt voor verdere training van het model.
                        \n
                        \n
                        Wachttijd:\n
                        Het genereren van punten en feedback kan tot ongeveer 50 seconden duren.
                        \n
                        \n
                        Contact:\n
                        Heb je vragen? Stuur dan een e-mail naar olaf@etalia.nl of een WhatsApp-bericht naar 0623991946.
                        "
                        buttonClasses={[
                            new buttonClass({
                                title: "Niet akkoord en zelf nakijken",
                                buttonFunc: () => toggleShowAutogradingConsent(false),
                            }),
                            new buttonClass({
                                title: "Akkoord, genereer feedback en punten",
                                buttonFunc: () => feedbackGeneren(),
                            })
                        ]}
                    />
                }
            />
        </div>
    )
}

export default CheckExam;
