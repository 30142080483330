
import React, { useState, useEffect } from 'react';

// style
import "./QuestionPointer.scss";

// elements
import Pointer from "../../../../../../../../elements/Pointer";

// constants
import {DOWN, RIGHT} from "../../../../../../../../elements/Pointer/services/constants";

const InfoPointer = (props) => {

    const toggleShowInfoFunc = (e) => {
        e.stopPropagation();

        if (e.shiftKey) {
            props.toggleShowQuestionInfo(!props.showInfo);
        }

        // it seems this should be in an else statement but doesn't work without it.
        props.toggleShowInfo((value) => !value);
    }
    useEffect(() => {
        props.toggleShowInfo(props.showQuestionInfo);
    
    }, [props.showQuestionInfo]);

    return (
        <div 
            className="questionNumber"
            onClick={(e) => toggleShowInfoFunc(e)}
        >
            No. {props.number}
        
            <div className="QuestionInfoPointer" >
                <Pointer
                    cssOff={true}
                    direction={props.showInfo ? DOWN : RIGHT}
                />
            </div>
        </div>
    )
}

export default InfoPointer;
