
import { useState, useEffect, useRef } from "react";

// redux
import { useDispatch, useSelector } from 'react-redux';

// styles
import "../../../components/StickyButtons/StickyButton/ExamStickyButton.scss";

// classes
import errorClass from "../../../../../services/errors/classes/errorClass";

// service
import handleLocalStorage from "../../../../../services/localStorage/localStorage";
import examEvaluation from "../../../../../services/Networking/examEvaluation/examEvaluation";
import handleEnvironment from "../../../../../services/handleEnvironment/handleEnvironment";
import authApi from "../../../../../services/Networking/authentication/authentication";
import networking from "../../../../../services/handleNetworking/networking";

// components
import Redux from "../../../../../components/Redux/Redux";
import Loader from "../../../../../components/Loader";

// constants
import { ADD_LOCAL_STORAGE, FETCH_LOCAL_STORAGE, REMOVE_LOCAL_STORAGE } from "../../../../../services/localStorage/constants";
import { LAST_ERROR_GENERATED_FEEDBACK } from "../../../services/constants/feedbackGeneration";
import { ETALIA_ENV } from "../../../../../services/handleEnvironment/constants";
import { INTERNAL_ERROR, SUCCESS } from "../../../../../services/errors/constants";
import { REDUX_ERRORS } from "../../../../../components/Redux/services/constants";
import { ALLOW_GENERATED_FEEDBACK_KEY } from "../../../../MyProfile/components/Settings/utils/constants";

const GenerateFeedbackButton = (props) => {

    const intervalRef = useRef(null);

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // data
    const [userSettings, updateUserSettings] = useState(null);

    // states
    const [loading, toggleLoading] = useState(false);
    const [generateFeedbackWaitTimer, generateFeedbackWaitTimerUpdate] = useState(null);

    // errors
    const [generateFeedbackError, updateGenerateFeedbackError] = useState(null);

    const startCountdown = (startingTime) => {
        generateFeedbackWaitTimerUpdate(startingTime);

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

       intervalRef.current = setInterval(() => {
            generateFeedbackWaitTimerUpdate((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
    };

    useEffect(() => {
        // On success of feedback generation, I don't toggle loading off.
        // If I do, loading turns off for a second and button appreas for a split second while exam is fetched.
        // So when exam arives, loading is off.
        toggleLoading(false);

        if (props.exam?.sent?.agreedToGenerateFeedback === true && props.exam?.sent?.checkedByAI === false) {

            const lastError = handleLocalStorage({
                action: FETCH_LOCAL_STORAGE,
                key: LAST_ERROR_GENERATED_FEEDBACK,
            });

            if (lastError) {
                const dateError = new Date(lastError);
                const currentTime = new Date();
                const timeDifferenceInSeconds = Math.floor((currentTime - dateError) / 1000);
                const baseWaitingTime = 60 - timeDifferenceInSeconds;

                if (baseWaitingTime > 0) {
                    startCountdown(baseWaitingTime);
                    return;
                }
                handleLocalStorage({
                    action: REMOVE_LOCAL_STORAGE,
                    key: LAST_ERROR_GENERATED_FEEDBACK,
                });
            }
        }
        generateFeedbackWaitTimerUpdate(0);
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [props.exam, props.showAutogradingPermissions]);

    useEffect(() => {
        fetchUserSettings()
    }, []);

    const fetchUserSettings = async() => {
        await networking({
            api: authApi.getUserSettings,
            apiParams: user?.user?.userId,

            updateContent: updateUserSettings,
        });
    }

    const feedbackGeneren = async() => {
        toggleLoading(true);
        handleLocalStorage({
            action: REMOVE_LOCAL_STORAGE,
            key: LAST_ERROR_GENERATED_FEEDBACK,
        });
        if (handleEnvironment() === ETALIA_ENV) { // ensure only uva can generate feedback
            return;
        }

        const feedback = await examEvaluation.postExamEvaluation(props.examId, props.examOrder);
        if (!feedback || feedback.status !== SUCCESS) {
            handleLocalStorage({
                action: ADD_LOCAL_STORAGE,
                key: LAST_ERROR_GENERATED_FEEDBACK,
                data: new Date()
            });
            updateGenerateFeedbackError(new errorClass({
                errorCode: INTERNAL_ERROR,
                description: "Feedback genereren niet gelukt. Het kan zijn dat er te veel studenten tegelijkertijd feedback aan het genereren zijn. Probeer het zo opnieuw."
            }));
            toggleLoading(false);
        } else {
            props.getExam();
        }
    }

    return (
        <>
            {
                loading ? <div className="StickyButtonExam"><Loader /></div> :
                generateFeedbackWaitTimer === null ? null :
                props.assignment?.canAIGrade === true && props.exam?.sent?.checkedByAI === false && userSettings?.[ALLOW_GENERATED_FEEDBACK_KEY] === true ?
                <div className="StickyButtonExam" onClick={generateFeedbackWaitTimer === 0 ? () => feedbackGeneren() : null} >{
                    generateFeedbackWaitTimer === 0 ? "(Opnieuw) Feedback Genereren" :
                    `Feedback genereren kan weer in: ${generateFeedbackWaitTimer}`
                }</div> : null
            }

            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}

                reduxVar={generateFeedbackError}
            />
        </>
    )
}

export default GenerateFeedbackButton;
