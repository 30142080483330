
// react
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// styles
import "./Assignments.scss";

// classes
import CustomErrorsManager from '../../services/errors/classes/CustomErrorsManager';

// services
import access from '../../services/access/accessCheck/access';
import networking from '../../services/handleNetworking/networking/networking';
import assignmentApi from '../../services/Networking/assignment/assignment';

// components
import List from '../../components/List';
import Loader from '../../components/Loader';
import Redux from '../../components/Redux/Redux';
import AddAssignment from './components/AddAssignment/AddAssignment';
import LocalModal from '../../components/LocalModal';
import Assignment from './components/Assignment/Assignment';

// constants
import { ADMIN, EDITOR, STUDENT, TEACHER } from '../../services/access/constants';
import { REDUX_ERRORS } from '../../components/Redux/services/constants';
import { INTERNAL_ERROR, UNAUTHORIZED } from '../../services/errors/constants';

const Assignments = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // data
    const [assignments, updateAssignments] = useState([]);

    // state
    const [loading, toggleLoading] = useState(false);
    const [addAssignment, toggleAddAssignment] = useState(false);

    // errors
    const [getAssignmentsError, updateGetAssignmentsError] = useState(null);

    const renderAssignment = (i, data) => {

        const formattedDeadline = data?.deadline ? new Date(data?.deadline).toLocaleDateString('nl-NL', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }) : "Nog geen deadline beschikbaar";

        return (
            <Assignment
                key={data?.id}
                data={data}
                getAssignments={getAssignments}
            />
        )
    }

    const getAssignments = async() => {
        networking({
            updateContent: updateAssignments,
            toggleLoading: toggleLoading,
            errorOnSuccess: false,

            api: access({
                accessLevel: STUDENT,
                userRoles: user?.user?.roles
            }) ? assignmentApi.getAssignedAssignments : assignmentApi.getAssignments,

            updateError: updateGetAssignmentsError,
            customErrors: (errorCode) => new CustomErrorsManager(
                {
                    [INTERNAL_ERROR]: {
                        title: "Het ophalen van de opdrachten is niet gelukt."
                    },
                    [UNAUTHORIZED]: {
                        title: "Je moet ingelogd zijn om je opdrachten te zien."
                    }
                }
            ).getError(errorCode)
        });
    }

    useEffect(() => {
        if (user?.user && !getAssignmentsError) {
            getAssignments();
        }
    }, [user]);

    return (
        <div
            className="AssignmentsView"
        >
            <div
                className="AssignemtsTitle"
            >
                Opdrachten

                {
                    !user ? null : !user.user ? null : access({
                        accessLevel: [ADMIN, EDITOR, TEACHER],
                        userRoles: user.user.roles
                    }) ? 
                    <div
                        className="AssignmentsAdd"
                        onClick={() => toggleAddAssignment(true)}
                    >
                        Opdracht toevoegen
                    </div> : null
                }

                {
                    loading && assignments ?
                    <div
                        className="AssignmentsLoader"
                    >
                        <Loader />
                    </div>
                    : null
                }

            </div>

            {
                loading && !assignments ?
                <Loader />
                :
                assignments?.length === 0 ?
                <div className="AssignmentsNoAssignments">Je hebt nog geen opdrachten</div>
                :
                <List
                    items={assignments}
                    renderItem={renderAssignment}
                />
            }

            <LocalModal
                show={addAssignment}
                toggleShow={toggleAddAssignment}
                component={
                    <AddAssignment
                        toggleShow={toggleAddAssignment}
                    />
                }
            />

            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={getAssignmentsError}
            />
        </div>
    )
}

export default Assignments;
