
import { useState, useEffect } from "react";

// chart
import {
    ErrorBar, LineChart, Bar, Legend, BarChart, Label, ReferenceDot, AreaChart, ScatterChart, Scatter, Area, ReferenceArea, Line, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';

// styling
import "./StudentPerformancePlot.scss";
import "../graph.scss";

// components
import BooleanSelect from "../../input/basicInput/booleanSelect/BooleanSelect";

// constants
import { AVERAGE_GRAPH, KWARTILE_GRAPH } from "../../../Views/Exams/Views/GradeAssignedExam/components/Satistics/constants";

const StudentPerformancePlot = (props) => {

    const [showLineGraph, toggleShowLineGraph] = useState(true);
    const [scatterActive, toggleScatterActive] = useState(true);
    const [middleLineActive, toggleMiddleLineActive] = useState(true);
    const [topLineActive, toggleTopLineActive] = useState(true);
    const [lowerLineActive, toggleLowerLineActive] = useState(true);
    const [maxPointsActive, toggleMaxPointsActive] = useState(false);

    const toggleUpperAndLower = () => {
        toggleTopLineActive(value => !value);
        toggleLowerLineActive(value => !value);
    }

    return (
        <div className="graphView">
            {
                !props.title ? null :
                <div className="graphTitle">{
                    props.title
                }</div>
            }

            <div className="StudentPerformancePlotButtonContainerView" >
                <div className="StudentPerformancePlotButtonsView" >
                    <BooleanSelect
                        title="Lijndiagram"
                        selected={showLineGraph}
                        toggleSelected={toggleShowLineGraph}
                    />
                    <BooleanSelect
                        title="Staafdiagram"
                        selected={!showLineGraph}
                        toggleSelected={toggleShowLineGraph}
                    />
                </div>
                <div className="StudentPerformancePlotButtonsView" >
                    <BooleanSelect
                        title={showLineGraph ? "Gemiddelde en Std" : "Gemiddelde"}
                        selected={props.graphToShow === AVERAGE_GRAPH}
                        toggleSelected={() => props.updateGraph(AVERAGE_GRAPH)}
                    />
                    <BooleanSelect
                        title="Kwartielen"
                        selected={props.graphToShow === KWARTILE_GRAPH}
                        toggleSelected={() => props.updateGraph(KWARTILE_GRAPH)}
                    />
                </div>
                <div className="StudentPerformancePlotButtonsView" >
                    <BooleanSelect
                        title="Percentage"
                        selected={!props.showPoints}
                        toggleSelected={() => props.toggleShowPoints(!props.showPoints)}
                    />
                    <BooleanSelect
                        title="Punten"
                        selected={props.showPoints}
                        toggleSelected={() => props.toggleShowPoints(!props.showPoints)}
                    />
                </div>
                <div className="StudentPerformancePlotButtonsView">
                    {
                        props.showPoints && showLineGraph ?
                        <BooleanSelect
                            title="Max punten"
                            selected={maxPointsActive}
                            toggleSelected={toggleMaxPointsActive}
                        /> : null
                    }
                    <BooleanSelect
                        title="Individuele punten"
                        selected={scatterActive}
                        toggleSelected={toggleScatterActive}
                    />
                    {
                        showLineGraph ? 
                        <BooleanSelect
                            title={props.graphToShow === AVERAGE_GRAPH ? "Gemiddelde" : "Mediaan"}
                            selected={middleLineActive}
                            toggleSelected={toggleMiddleLineActive}
                        /> : null
                    }
                    {
                        showLineGraph ? 
                        <BooleanSelect
                            title={props.graphToShow === AVERAGE_GRAPH ? "Standaarddeviatie" : "Kwartielen"}
                            selected={lowerLineActive}
                            toggleSelected={toggleUpperAndLower}
                        /> : null
                    }
                </div>
            </div>
            
            {
            !props.data ? null :
                showLineGraph ? 
                <ResponsiveContainer width="100%" height={400}>
                <AreaChart data={props.data} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="question" name="Question" type="number" domain={props.domainX} allowDecimals={false} >
                        <Label value="Vragen" position="insideBottom" offset={-10} />
                    </XAxis>

                    <YAxis dataKey="y" name="Percentage of Points" domain={props.domainY} allowDecimals={false} >
                        <Label value={!props.showPoints ? "Percentage van max punten" : "Punten"} position="insideLeft" angle={-90} dy={!props.showPoints ? 100 : 50} />
                    </YAxis>
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                    <Legend verticalAlign="bottom" align="center" wrapperStyle={{ paddingTop: 20 }} />
                    {
                        middleLineActive ?
                        <Area type="monotone" dot={{ r: 3 }} dataKey={props.keys[props.graphToShow]?.middleLine} data={props.data} stroke="#8884d8" fillOpacity={0} /> : null
                    }
                    {
                        topLineActive && lowerLineActive ?
                        <Area type="monotone" dot={{ r: 3 }} dataKey={props.keys[props.graphToShow]?.range} data={props.data} stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.3} />
                        : null
                    }
                    {
                        maxPointsActive ?
                        <Area type="monotone" dot={{ r: 3 }} dataKey={props.keys[props.graphToShow]?.max} data={props.data} stroke="#be3a3a" fill="#82ca9d" fillOpacity={0} />
                        : null
                    }
                    {
                        scatterActive && props.scatterData ?
                        props.scatterData.map((point) => {
                            return <ReferenceDot x={point.question} y={point.y} r={4} fill="red" stroke="none" />
                        }) : null
                    }
                </AreaChart>
                </ResponsiveContainer>
                : 
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                        data={props.data}
                        margin={{
                            top: 20, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        {/* <XAxis dataKey="question"  /> */}
                        <XAxis dataKey="question" name="Question" type="number" allowDecimals={false} >
                            <Label value="Vragen" position="insideBottom" offset={-10} />
                        </XAxis>
                        <YAxis>
                            <Label value={!props.showPoints ? "Percentage van max punten" : "Punten"} angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} domain={props.domainY} />
                        </YAxis>
                        <Tooltip />
                        <Legend verticalAlign="bottom" align="center" wrapperStyle={{ paddingTop: 20 }} />

                        <Bar dataKey={props.keys[props.graphToShow]?.middleLine} stackId="a" fill="#008000" name={props.graphToShow === AVERAGE_GRAPH ? "Gemiddeld Behaalde Punten" : "Mediaan Behaalde Punten"} >
                            {/* <ErrorBar dataKey={props.keys[props.graphToShow]?.errorBarY} width={4} strokeWidth={2} stroke="#be3a3a" direction="y" /> */}
                        </Bar>
                        <Bar dataKey={props.keys[props.graphToShow]?.missedPoints} stackId="a" fill="#f86161" name={props.graphToShow === AVERAGE_GRAPH ? "Gemiddeld Gemiste Punten" : "Mediaan Gemiste Punten"} />
                        {
                            scatterActive && props.scatterData ?
                            props.scatterData.map((point) => {
                                return <ReferenceDot x={point.question} y={point.y} r={4} fill="red" stroke="none" />
                            }) : null
                        }
                    </BarChart>
                </ResponsiveContainer>
        }</div>
    )
}

export default StudentPerformancePlot;

// {
//     "std": [
//       {
//         "question": 1,
//         "std": [-18.30127018922193, 68.30127018922192],
//         "percentage": 25
//       },
//       {
//         "question": 2,
//         "std": [0, 100],
//         "percentage": 50
//       },
//       {
//         "question": 3,
//         "std": [0, 100],
//         "percentage": 50
//       }
//     ],
//     "areadStd": [
//       {
//         "question": 1,
//         "low": -18.30127018922193,
//         "high": 68.30127018922192
//       },
//       {
//         "question": 2,
//         "low": 0,
//         "high": 100
//       },
//       {
//         "question": 3,
//         "low": 0,
//         "high": 100
//       }
//     ]
//   }
  